<template>
  <b-card
    header-bg-variant="transparent"
    footer-bg-variant="transparent"
    header-class="d-flex justify-content-between align-items-center"
    footer-class="d-flex justify-content-between align-items-center"
  >
    <template v-slot:header>
      <h5 class="mb-0">Educacional</h5>
      <b-button :to="{ name: 'education' }" size="sm" variant="outline-primary">Educação</b-button>
    </template>
    <b-row class="h-100" v-if="articles.length == 0">
      <b-col class="d-flex justify-content-center align-items-center">
        <div>Artigos em desenvolvimento</div>
      </b-col>
    </b-row>
    <b-list-group flush class="overflow-auto" v-else>
      <b-list-group-item>
        <b-row>
          <b-col cols="8">
            <span class="font-weight-bold">Artigo</span>
          </b-col>
          <b-col cols="4" class="text-right">
            <span class="font-weight-bold">Data</span>
          </b-col>
        </b-row>
      </b-list-group-item>
      <b-list-group-item
        v-for="(article, index) in articles"
        :key="index"
        style="cursor: pointer"
        :to="{ name: 'education-article-view', params: { articleId: article.id } }"
      >
        <b-row>
          <b-col cols="12">
            <span class="mr-auto">{{ article.name }}</span>
          </b-col>
        </b-row>
      </b-list-group-item>
    </b-list-group>
  </b-card>
</template>
<script>
import ApiService from "../../common/api.service";
export default {
  name: "WidgetEducation",
  components: {},
  data() {
    return {
      isLoadingArticles: false,
      articles: [],
    };
  },
  computed: {},
  beforeMount() {},
  mounted() {
    this.getWidgetArticles();
  },
  methods: {
    getWidgetArticles: function () {
      this.isLoadingArticles = true;
      ApiService.get("education/getWidgetArticles")
        .then((response) => {
          if (response.data.status === "success") {
            this.articles = response.data.articles;
          }
        })
        .finally(() => {
          this.isLoadingArticles = false;
        });
    },
  },
};
</script>
