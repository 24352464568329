const routes = [
  {
    path: "/contracts",
    name: "contracts-dashboard",
    component: () => import(/* webpackChunkName: "contracts-dashboard" */ "../views/contracts/index.vue"),
    meta: { section_name: "contracts" },
    props: true,
  },
  {
    path: "/contracts/:contractId/view",
    name: "contract-view",
    component: () => import(/* webpackChunkName: "contract-view" */ "../views/contracts/view-contract.vue"),
    meta: { section_name: "contracts" },
    props: (route) => {
      return { ...route.params, ...{ contractId: Number.parseInt(route.params.contractId, 10) } };
    },
  },
  {
    path: "/contracts/new",
    name: "contract-create-new",
    component: () => import(/* webpackChunkName: "contract-create-new" */ "../views/contracts/create-contract-new.vue"),
    meta: { section_name: "contracts" },
    props: true,
  },
];
export default routes;
