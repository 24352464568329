<template>
  <div>
    <!--  BEGIN NAVBAR  -->
    <div class="header-container fixed-top">
      <header class="header navbar navbar-expand-sm">
        <ul class="navbar-item theme-brand flex-row text-center">
          <!-- <li class="nav-item theme-logo">
            <router-link to="/">
              <img src="@/assets/images/epal.png" class="navbar-logo" alt="logo" />
            </router-link>
          </li> -->
          <li class="nav-item theme-text">
            <router-link to="/" class="nav-link">
              <img src="./../../assets/images/epal.png" alt="avatar" style="width: 170px" />
            </router-link>
          </li>
        </ul>
        <div class="d-none horizontal-menu">
          <a
            href="javascript:void(0);"
            class="sidebarCollapse"
            data-placement="bottom"
            @click="$store.commit('toggleSideBar', !$store.state.is_show_sidebar)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-menu"
            >
              <line x1="3" y1="12" x2="21" y2="12" />
              <line x1="3" y1="6" x2="21" y2="6" />
              <line x1="3" y1="18" x2="21" y2="18" />
            </svg>
          </a>
        </div>
        <ul class="navbar-item flex-row ml-md-0 ml-auto" />
        <div class="navbar-item flex-row ml-md-auto mr-md-auto d-none d-sm-block">
          <!-- <span class="text-white">Epal Engenharia</span> -->
        </div>
        <!-- ml-md-auto -->
        <div class="navbar-item flex-row">
          <b-dropdown
            toggle-tag="a"
            variant="icon-only"
            toggle-class="user nav-link"
            class="nav-item user-profile-dropdown"
            :right="true"
          >
            <template #button-content>
              <span v-if="user" class="mr-2 text-white d-none d-sm-block">{{ user.name }}</span>
              <img :src="user ? user.avatar : null" alt="avatar" />
            </template>
            <b-dropdown-divider />
            <b-dropdown-item to="/users/profile">
              <i class="fa-regular fa-user fa-fw" />
              Dados Pessoais
            </b-dropdown-item>

            <b-dropdown-item @click="userLogout()">
              <i class="fa-regular fa-power-off fa-fw" />
              Sair
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </header>
    </div>
    <!--  END NAVBAR  -->
    <!--  BEGIN NAVBAR  -->
    <div class="sub-header-container">
      <header class="header navbar navbar-expand-sm">
        <a
          href="javascript:void(0);"
          class="sidebarCollapse"
          data-placement="bottom"
          @click="$store.commit('toggleSideBar', !$store.state.is_show_sidebar)"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-menu"
          >
            <line x1="3" y1="12" x2="21" y2="12" />
            <line x1="3" y1="6" x2="21" y2="6" />
            <line x1="3" y1="18" x2="21" y2="18" />
          </svg>
        </a>

        <!-- Portal vue for Breadcrumb -->
        <portal-target name="breadcrumb" />
      </header>
    </div>
    <!--  END NAVBAR  -->
    <!--  BEGIN TOPBAR  -->
    <div
      class="topbar-nav header navbar"
      :class="$store.state.routeMetaLayout === 'work' ? 'horizontal-topbar-nav-header' : null"
      role="banner"
    >
      <nav class="topbar">
        <header-menu />
      </nav>
    </div>

    <!--  END TOPBAR  -->
  </div>
</template>
<script>
import Vue from "vue";
import ApiService from "../../common/api.service";
import EchoService from "../../common/echo";
import { mapState } from "vuex";
import headerMenu from "./header-menu.vue";

export default {
  name: "EpalHeader",
  components: { headerMenu },
  data() {
    return {
      selectedLang: null,
      countryList: this.$store.state.countryList,
      notifications: [],
      activeCompany: null,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      userpermissions: (state) => state.auth.userpermissions,
      channelEpal: (state) => state.channelEpal,
    }),

    showDevStuffs: function () {
      return process.env.NODE_ENV === "development";
    },
    userRole: function () {
      let userRole = "Sem Perfil Selecionado";
      if (this.user) {
        if (this.user.roles.length > 0) {
          userRole = this.user.roles[0].display_name;
        }
      }
      return userRole;
    },
  },
  watch: {},
  created: function () {},
  mounted() {
    this.getAuthUser();
    this.selectedLang = this.$appSetting.toggleLanguage();
    this.toggleMode();
    // this.setMenuStyle();

    if (!("Notification" in window)) {
      console.log("This browser does not support desktop notification");
    } else if (Notification.permission === "granted") {
      // console.log('Notification enabled');
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          // console.log('Notification enabled');
        } else {
          // console.log('Notification disabled');
        }
      });
    }
  },
  beforeDestroy: function () {
    if (this.user) {
      Echo.leave(`private-App.Models.User.${this.user.id}`);
    }
    Echo.leave("EpalChannel");
  },

  methods: {
    setMenuStyle(value) {
      this.$store.commit("toggleMenuStyle", value);
    },
    toggleMode(mode) {
      this.$appSetting.toggleMode(mode);
    },

    changeLanguage(item) {
      this.selectedLang = item;
      this.$appSetting.toggleLanguage(item);
    },
    userLogout: async function () {
      this.$store.dispatch("logout");
    },
    getAuthUser: function () {
      ApiService.get("auth/getAuthUser").then((response) => {
        this.$store.dispatch("authUser", response);
        Vue.nextTick(() => {
          EchoService.init();

          Echo.join("EpalChannel")
            .here((users) => {
              this.$store.commit("updateChannelEpal", {
                key: "activeEpalUsers",
                value: users,
              });
            })
            .joining((user) => {
              this.$store.commit("updateChannelEpal", {
                key: "userEpalMovement",
                value: { user, side: "entrou" },
              });
              this.$store.commit("updateChannelEpalPushUser", user);
            })
            .leaving((user) => {
              this.$store.commit("updateChannelEpal", {
                key: "userEpalMovement",
                value: { user, side: "saiu" },
              });
              this.$store.commit("updateChannelEpalSpliceUser", {
                userId: user.id,
              });
            })
            .error((error) => {
              console.error(error);
            });
        });
      });
    },
  },
};
</script>
<style scoped>
.company-name {
  font-size: 10px;
}
.navbar {
  background: #0e1726;
}
</style>
