import mutations from "./mutations";

const state = () => ({
  project: { id: null, name: null },
  projectMenuName: null,
  projectSubMenuName: null,
  isLoadingProject: false,
  projectteam: null,
  projectUserRole: null,
  projectUserPermissions: [],
});

const actions = {};

const getters = {
  isLoadingProject: (state) => state.isLoadingProject,
  project: (state) => state.project,
  projectMenuName: (state) => state.projectMenuName,
  projectSubMenuName: (state) => state.projectSubMenuName,
  projectteam: (state) => state.projectteam,
  projectUserRole: (state) => state.projectUserRole,
  projectUserPermissions: (state) => state.projectUserPermissions,
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
  getters,
};
