const routes = [
  {
    path: "/projects",
    name: "projects-dashboard",
    component: () => import(/* webpackChunkName: "projects-dashboard" */ "../views/projects/index.vue"),
    meta: { section_name: "projects", layout: "projects" },
    props: true,
  },
  {
    path: "/project/new",
    name: "project-create-new",
    component: () => import(/* webpackChunkName: "project-create-new" */ "../views/projects/create-project-new.vue"),
    meta: { section_name: "projects", layout: "projects" },
    props: true,
  },
  {
    path: "/project/:projectId/edit",
    name: "project-create",
    component: () => import(/* webpackChunkName: "project-create" */ "../views/projects/create-project.vue"),
    meta: { section_name: "projects", layout: "projects" },
    props: (route) => {
      return {
        ...route.params,
        ...{
          projectId: Number.parseInt(route.params.projectId, 10),
        },
      };
    },
  },
  {
    path: "/project/:projectId/:activeTab",
    name: "project-view",
    component: () => import(/* webpackChunkName: "projects-view" */ "../views/projects/view-project.vue"),
    meta: { section_name: "projects", layout: "projects" },
    props: (route) => {
      return {
        ...route.params,
        ...{
          projectId: Number.parseInt(route.params.projectId, 10),
          activeTab: route.params.activeTab,
        },
      };
    },
  },
  { path: "/projects/:projectId", redirect: "/projects/:projectId/informations" },
];
export default routes;
