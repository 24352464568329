import mutations from "./mutations";

const state = () => ({
  channelEpal: {
    activeEpalUsers: [],
    userEpalMovement: { user: null, side: null },
    updatedAt: null,
  },
});

const actions = {};

const getters = {
  channelEpal: (state) => state.channelEpal,
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
  getters,
};
