import mutations from "./mutations";

const state = () => ({
  registerProgressOnEnterActivity: false,
});

const actions = {};

const getters = {
  registerProgressOnEnterActivity: (state) => state.registerProgressOnEnterActivity,
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
  getters,
};
