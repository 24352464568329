const routes = [
  {
    path: "/engineers",
    name: "engineers",
    component: () => import(/* webpackChunkName: "engineers" */ "../views/engineers/index.vue"),
    meta: { section_name: "engineers" },
    props: true,
  },
  {
    path: "/engineers/:engineerId",
    name: "engineers-view",
    component: () => import(/* webpackChunkName: "engineers-view" */ "../views/engineers/view-engineer.vue"),
    meta: { section_name: "engineers" },
    props: (route) => {
      return { ...route.params, ...{ engineerId: Number.parseInt(route.params.engineerId, 10) } };
    },
  },
];
export default routes;
