import store from "../store/index";
import Echo from "laravel-echo";
import JwtService from "./jwt.service";

let serverURL;
let key = null;

if (process.env.NODE_ENV === "production") {
  serverURL = "https://api.epalengenharia.com.br/api/broadcasting/auth";
  key = "71806cca89321228c993";
} else {
  serverURL = "https://api.epalengenharia.test/api/broadcasting/auth";
  key = "71806cca89321228c993";
}

const EchoService = {
  init() {
    window.Pusher = require("pusher-js");

    // Pusher.log = (message) => {
    //   if (window.console && window.console.log) {
    //     window.console.log(message);
    //   }
    // };

    window.Echo = new Echo({
      broadcaster: "pusher",
      key,
      cluster: "mt1",
      encrypted: true,
      authEndpoint: serverURL,
      auth: {
        headers: {
          Authorization: `Bearer ${JwtService.getToken()}`,
        },
      },
    });

    window.Echo.connector.pusher.connection.bind("connected", () => {
      //   store.commit('updatePusherStatus', 'connected')
    });
    window.Echo.connector.pusher.connection.bind("disconnected", () => {
      //   store.commit('updatePusherStatus', 'disconnected')
    });

    window.Echo.channel(`private-App.Models.User.${store.state.auth.user.id}`).listen(
      "NotifyUserOnPrivateChannel",
      (e) => {
        console.log(e);
      }
    );
    // .error((error) => {
    //   store.commit('updatePusherStatus', error.type)
    // })
  },
};

export default EchoService;
