const routes = [
  {
    path: "/activities",
    name: "activities-dashboard",
    component: () => import(/* webpackChunkName: "activities-dashboard" */ "../views/activities/index.vue"),
    meta: { section_name: "activities" },
    props: true,
  },
  {
    path: "/activities/new",
    name: "activity-create-new",
    component: () =>
      import(/* webpackChunkName: "activity-create-new" */ "../views/activities/create-activity-new.vue"),
    meta: { section_name: "activities" },
    props: (route) => {
      return {
        ...route.params,
        ...{
          projectId: Number.parseInt(route.params.projectId, 10),
        },
      };
    },
  },
  {
    path: "/activities/progress/:progressId/",
    name: "view-activity-progress",
    component: () =>
      import(/* webpackChunkName: "view-activity-progress" */ "../views/activities/view-activity-progress.vue"),
    meta: { section_name: "activities" },
    props: (route) => {
      return {
        ...route.params,
        ...{
          projectId: Number.parseInt(route.params.projectId, 10),
          activityId: Number.parseInt(route.params.activityId, 10),
        },
      };
    },
  },
  {
    path: "/project/:projectId/activities/new",
    name: "project-activity-create-new",
    component: () =>
      import(/* webpackChunkName: "project-activity-create-new" */ "../views/activities/create-activity-new.vue"),
    meta: { section_name: "projects" },
    props: (route) => {
      return {
        ...route.params,
        ...{
          projectId: Number.parseInt(route.params.projectId, 10),
        },
      };
    },
  },
  {
    path: "/project/:projectId/activities/:activityId",
    name: "project-activity",
    component: () => import(/* webpackChunkName: "project-activity" */ "../views/projects/project-activity.vue"),
    meta: { section_name: "projects" },
    props: (route) => {
      return {
        ...route.params,
        ...{
          projectId: Number.parseInt(route.params.projectId, 10),
          activityId: Number.parseInt(route.params.activityId, 10),
        },
      };
    },
  },
];
export default routes;
