<template>
  <div class="d-flex justify-content-center align-items-center flex-column full-height-class">
    <div>
      <i class="fa-solid fa-cog fa-spin fa-5x" />
      <i class="fa-solid fa-cog fa-spin fa-5x" style="--fa-animation-direction: reverse" />
    </div>

    <span class="font-weight-bold mt-3">{{ message }}</span>
  </div>
</template>

<script>
export default {
  name: "EpalLoading",
  components: {},
  filters: {},
  props: { message: { type: String, default: "Carregando ..." } },
  data() {
    return {};
  },
  computed: {},
  mounted: function () {},
  beforeDestroy: function () {},
  methods: {},
};
</script>

<style scoped>
.full-height-class {
  height: calc(100vh - 109px);
}
</style>
