import Home from "../views/home/index.vue";
import ConfirmInvite from "../views/auth/invites/ConfirmInvite.vue";

const routes = [
  // dashboard
  { path: "/", name: "Home", component: Home },
  {
    path: "/invite/confirme/:code",
    name: "confirmInvite",
    component: ConfirmInvite,
    props: true,
    meta: { layout: "auth" },
  },
  {
    path: "/password/forget",
    name: "forgetpassword",
    component: () =>
      import(
        /* webpackChunkName: "forgetpassword" */ "../views/auth/pass_recovery.vue"
      ),
    meta: { layout: "auth" },
    
  },
  {
    path: "/password/forget/:code",
    name: "resetpassword",
    component: () =>
      import(
        /* webpackChunkName: "forgetpassword" */ "../views/auth/pass_recovery.vue"
      ),
    meta: { layout: "auth" },
    props: true,
  },

  // auth
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/auth/login.vue"),
    meta: { layout: "auth" },
  },
  {
    path: "/register",
    name: "register",
    component: () =>
      import(/* webpackChunkName: "register" */ "../views/auth/register.vue"),
    meta: { layout: "auth" },
  },
  {
    path: "/auth/lockscreen",
    name: "lockscreen",
    component: () =>
      import(
        /* webpackChunkName: "lockscreen" */ "../views/auth/lockscreen.vue"
      ),
    meta: { layout: "auth" },
  },
  // users
  {
    path: "/users",
    name: "users",
    component: () =>
      import(/* webpackChunkName: "users-users" */ "../views/users/index.vue"),
    meta: { section_name: "users" },
    props: true,
  },
  {
    path: "/users/new",
    name: "new-user",
    component: () =>
      import(/* webpackChunkName: "new-user" */ "../views/users/new-user.vue"),
    meta: { section_name: "users" },
    props: true,
  },
  {
    path: "/users/:userId/edit",
    name: "edit-user",
    component: () =>
      import(
        /* webpackChunkName: "edit-user" */ "../views/users/create-user.vue"
      ),
    meta: { section_name: "users" },
    props: (route) => {
      return {
        ...route.params,
        ...{ userId: Number.parseInt(route.params.userId, 10) },
      };
    },
  },
  {
    path: "/users/profile",
    name: "profile",
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/users/profile.vue"),
  },
  {
    path: "/users/account-setting",
    name: "account-setting",
    component: () =>
      import(
        /* webpackChunkName: "account-setting" */ "../views/users/account_setting.vue"
      ),
  },
  {
    path: "/users/invites/:userId",
    name: "listInvites",
    component: () =>
      import(/* webpackChunkName: "new-user" */ "../views/invites/Invites.vue"),
    // meta: { section_name: "users" },
    props: true,
  },
  {
    path: "/companies",
    name: "companies",
    component: () =>
      import(
        /* webpackChunkName: "companies" */ "../views/companies/index.vue"
      ),
    meta: { section_name: "companies" },
    props: true,
  },
  {
    path: "/companies/new",
    name: "new-company",
    component: () =>
      import(
        /* webpackChunkName: "new-company" */ "../views/companies/new-company.vue"
      ),
    meta: { section_name: "companies" },
    props: true,
  },
  {
    path: "/companies/:companyId/edit",
    name: "edit-company",
    component: () =>
      import(
        /* webpackChunkName: "edit-company" */ "../views/companies/create-company.vue"
      ),
    meta: { section_name: "companies" },
    props: (route) => {
      return {
        ...route.params,
        ...{ companyId: Number.parseInt(route.params.companyId, 10) },
      };
    },
  },
];
export default routes;
