import Vue from "vue";
import moment from "moment";
moment.locale("pt-Br");

Vue.filter("formatShortDate", function (value) {
  if (value) {
    return moment(value).format("DD/MM/YYYY");
  } else {
    return null;
  }
});

Vue.filter("formatDateTime", function (value) {
  if (value) {
    return moment(value).format("DD/MM/YYYY HH:mm");
  } else {
    return null;
  }
});
Vue.filter("formatDateTimeForHumans", function (value) {
  if (value) {
    return moment(String(value)).fromNow();
  } else {
    return null;
  }
});
Vue.filter("fractionToTime", function (value) {
  if (!value) return "00:00";

  // Multiplicar a fração de hora por 60 para obter o total de minutos
  let totalMinutes = value * 60;

  // Obter horas e minutos
  let hours = Math.floor(totalMinutes / 60);
  let minutes = Math.round(totalMinutes % 60);

  // Formatar para "HH:MM"
  let formattedHours = hours.toString().padStart(2, "0");
  let formattedMinutes = minutes.toString().padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}`;
});
Vue.filter("formatDecimalValue", function (value) {
  if (value) {
    const val = Number(value).toFixed(2).replace(".", ",");
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  } else {
    return "0,00";
  }
});
Vue.filter("translateContractType", function (value) {
  if (value) {
    if (value == "privado") {
      return "Privado";
    } else if (value == "publico") {
      return "Público";
    } else {
      return null;
    }
  } else {
    return null;
  }
});
Vue.filter("translateContractPartsType", function (value) {
  if (value) {
    if (value.indexOf("Company") > 0) {
      return "Pessoa Jurídica";
    } else if (value.indexOf("User") > 0) {
      return "Pessoa Física";
    } else {
      return null;
    }
  } else {
    return null;
  }
});
