<template>
  <div class="layout-px-spacing">
    <portal to="breadcrumb">
      <ul class="navbar-nav flex-row">
        <li>
          <div class="page-header">
            <nav class="breadcrumb-one" aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item active" aria-current="page">
                  <span>{{ $t("dashboard") }}</span>
                </li>
              </ol>
            </nav>
          </div>
        </li>
      </ul>
    </portal>
    <b-row class="layout-top-spacing">
      <b-col class="layout-spacing">
        <div id="epal-header" class="mb-3 d-flex justify-content-between align-items-baseline">
          <div>
            <h3 class="mb-0">Dashboard</h3>
            <span class="text-muted">Sistema de Gestão da Epal Engenharia</span>
          </div>
          <!-- <b-button pill variant="outline-primary">Novo Projeto</b-button> -->
        </div>
        <loading v-if="isLoading" message="Carregando Dashboard" />
        <div v-else>
          <b-row>
            <b-col cols="12" sm="12" md="6" class="mb-4" v-if="$checkPermission('ver-project-efficiency')">
              <widget-projects-efficiency class="h-100"></widget-projects-efficiency>
            </b-col>
            <b-col cols="12" sm="12" md="6" class="mb-4" v-if="$checkPermission('ver-user-progress')">
              <widget-user-progress class="h-100"></widget-user-progress>
            </b-col>
            <b-col cols="12" sm="12" md="6" class="mb-4" v-if="!$checkPermission('ver-project-efficiency')">
              <widget-projects class="h-100"></widget-projects>
            </b-col>
            <b-col cols="12" sm="12" md="6" class="mb-4">
              <widget-activities class="h-100"></widget-activities>
            </b-col>
            <b-col cols="12" sm="12" md="6" class="mb-4">
              <widget-education class="h-100"></widget-education>
            </b-col>
            <b-col cols="12" sm="12" md="6" class="mb-4" v-if="$checkPermission('ver-widget-contratos')">
              <widget-contracts class="h-100"></widget-contracts>
            </b-col>
            <b-col cols="12" sm="12" md="6" class="mb-4" v-if="$checkPermission('ver-usuarios-online')">
              <b-card
                header-bg-variant="transparent"
                footer-bg-variant="transparent"
                header-class="d-flex justify-content-between align-items-center"
                footer-class="d-flex justify-content-between align-items-center"
                class="h-100"
              >
                <template v-slot:header>
                  <h5 class="mb-0">Usuários Online</h5>
                  <b-button :to="{ name: 'users' }" size="sm" variant="outline-primary">Usuários</b-button>
                </template>

                <b-list-group flush class="chat-container overflow-auto">
                  <b-list-group-item v-for="(user, index) in channelEpal.activeEpalUsers" :key="index">
                    <!-- style="cursor: pointer" -->
                    <!-- @click="$router.push({ name: 'edit-user', params: { userId: user.id } })" -->
                    <b-row>
                      <b-col cols="12" sm="12">
                        <b-avatar variant="info" :src="user.avatar" class="mr-3" />
                        <span class="mr-auto">{{ user.name }}</span>
                      </b-col>
                    </b-row>
                  </b-list-group-item>
                </b-list-group>
              </b-card>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import "@/assets/sass/widgets/widgets.scss";
import "@/assets/sass/widgets/widgets-epal.scss";

// import horizontalWorks from './horizontal-works.vue'

// import ApiService from "../../common/api.service";
import { mapState } from "vuex";
import Loading from "../../components/loading.vue";
import WidgetUserProgress from "./widget-user-progress.vue";
import WidgetProjects from "./widget-projects.vue";
import WidgetContracts from "./widget-contracts.vue";
import WidgetActivities from "./widget-activities.vue";
import WidgetProjectsEfficiency from "./widget-projects-efficiency.vue";
import WidgetEducation from "./widget-education.vue";

export default {
  name: "EpalIndex",
  metaInfo: { title: "Dashboard" },
  components: {
    Loading,
    WidgetUserProgress,
    WidgetProjects,
    WidgetContracts,
    WidgetActivities,
    WidgetProjectsEfficiency,
    WidgetEducation,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapState({
      channelEpal: (state) => state.chEpal.channelEpal,
    }),
  },
  beforeMount() {},
  mounted() {},
  methods: {},
};
</script>
<style scoped>
.layout-px-spacing {
  min-height: calc(100vh - 170px) !important;
}
</style>
