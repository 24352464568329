import Vue from "vue";
import Vuex from "vuex";
import i18n from "../i18n";
import auth from "./auth";
import location from "./modules/location/actionCreator";
import chEpal from "./modules/chEpal/actionCreator";
import activities from "./modules/activities/actionCreator";
import projectData from "./modules/projectData/actionCreator";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    layout: "app",
    routeMetaLayout: "app",
    is_show_sidebar: true,
    is_show_search: false,
    is_dark_mode: false,
    dark_mode: "light",
    locale: null,
    menu_style: "horizontal",
    layout_style: "full",
    countryList: [
      { code: "brl", name: "Português" },
      // { code: 'es', name: 'Spanish' },
      { code: "en", name: "English" },
      // { code: 'pt', name: 'Português' },
    ],
    alert_show: false,
    alert_variant: null,
    alert_html: null,
  },
  mutations: {
    setLayout(state, payload) {
      state.layout = payload;
    },
    setRouteMetaLayout(state, payload) {
      state.routeMetaLayout = payload;
    },
    toggleSideBar(state, value) {
      state.is_show_sidebar = value;
    },
    toggleSearch(state, value) {
      state.is_show_search = value;
    },
    toggleLocale(state, value) {
      value = value || "brl";
      i18n.locale = value;
      localStorage.setItem("i18n_locale", value);
      state.locale = value;
    },

    toggleDarkMode(state, value) {
      // light|dark|system
      value = value || "light";
      localStorage.setItem("dark_mode", value);
      state.dark_mode = value;
      if (value === "light") {
        state.is_dark_mode = false;
      } else if (value === "dark") {
        state.is_dark_mode = true;
      } else if (value === "system") {
        if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
          state.is_dark_mode = true;
        } else {
          state.is_dark_mode = false;
        }
      }

      if (state.is_dark_mode) {
        document.querySelector("body").classList.add("dark");
      } else {
        document.querySelector("body").classList.remove("dark");
      }
    },

    toggleMenuStyle(state, value) {
      // horizontal|vertical|collapsible-vertical
      value = value || "";
      localStorage.setItem("menu_style", value);
      state.menu_style = value;
      if (!value || value === "vertical") {
        state.is_show_sidebar = true;
      } else if (value === "collapsible-vertical") {
        state.is_show_sidebar = false;
      }
    },

    toggleLayoutStyle(state, value) {
      // boxed-layout|large-boxed-layout|full
      value = value || "";
      localStorage.setItem("layout_style", value);
      state.layout_style = value;
    },

    updatePusherStatus(state, value) {
      state.pusherstatus = value;
    },
    toggleAlertShow(state, value) {
      state.alert_show = value;
    },
    setAlertVariant(state, value) {
      state.alert_variant = value;
    },
    setAlertHtml(state, value) {
      state.alert_html = value;
    },
  },
  getters: {
    layout(state) {
      return state.layout;
    },
  },
  actions: {},
  modules: { auth, location, chEpal, activities, projectData },
});
