<template>
  <!--  BEGIN SIDEBAR  -->
  <div class="sidebar-wrapper sidebar-theme">
    <nav id="sidebar" ref="menu">
      <div class="shadow-bottom" />

      <perfect-scrollbar
        class="list-unstyled menu-categories"
        tag="ul"
        :options="{
          wheelSpeed: 0.5,
          swipeEasing: !0,
          minScrollbarLength: 40,
          maxScrollbarLength: 300,
          suppressScrollX: true,
        }"
      >
        <router-link tag="li" to="/" class="menu" @click.native="toggleMobileMenu">
          <a class="dropdown-toggle">
            <div class="">
              <i class="fa-light fa-house fa-xl fa-fw" style="overflow: unset; margin-right: 14px" />
              <span>{{ $t("dashboard") }}</span>
            </div>
          </a>
        </router-link>

        <li class="menu">
          <a v-b-toggle href="#users" class="dropdown-toggle" @click.prevent>
            <div class="">
              <i class="fa-light fa-users fa-xl fa-fw" style="overflow: unset; margin-right: 14px" />
              <span>{{ $t("users") }}</span>
            </div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-chevron-right"
              >
                <polyline points="9 18 15 12 9 6" />
              </svg>
            </div>
          </a>
          <b-collapse id="users" accordion="menu">
            <ul class="collapse submenu list-unstyled show">
              <router-link tag="li" to="/users/profile" @click.native="toggleMobileMenu">
                <a>Profile</a>
              </router-link>
            </ul>
          </b-collapse>
        </li>
        <li class="menu">
          <a v-b-toggle href="#admin" class="dropdown-toggle" @click.prevent>
            <div class="">
              <!-- <i class="fa-light fa-screwdriver-wrench mr-3"></i> -->
              <i class="fa-light fa-gear fa-xl fa-fw" style="overflow: unset; margin-right: 14px" />
              <span>{{ $t("admin") }}</span>
            </div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-chevron-right"
              >
                <polyline points="9 18 15 12 9 6" />
              </svg>
            </div>
          </a>
          <b-collapse id="admin" accordion="menu">
            <ul class="collapse submenu list-unstyled show">
              <router-link
                v-if="$checkPermission('list-subscriptions')"
                tag="li"
                :to="{ name: 'subscription-plans' }"
                @click.native="toggleMobileMenu"
              >
                <a>Planos de Assinatura</a>
              </router-link>
              <router-link
                v-if="$checkPermission('list-subscriptions')"
                tag="li"
                :to="{ name: 'subscriptions' }"
                @click.native="toggleMobileMenu"
              >
                <a>Assinaturas</a>
              </router-link>
            </ul>
          </b-collapse>
        </li>
        <li class="menu">
          <a v-b-toggle href="#finance" class="dropdown-toggle" @click.prevent>
            <div class="">
              <i class="fa-light fa-dollar-sign fa-xl fa-fw" style="overflow: unset; margin-right: 14px" />
              <span>{{ $t("finance") }}</span>
            </div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-chevron-right"
              >
                <polyline points="9 18 15 12 9 6" />
              </svg>
            </div>
          </a>
          <b-collapse id="finance" accordion="menu">
            <ul class="collapse submenu list-unstyled show">
              <router-link
                v-if="$checkPermission('pode-ver-transferencias-iugu')"
                tag="li"
                :to="{ name: 'finance-iugu-transfers' }"
                @click.native="toggleMobileMenu"
              >
                <a>Transferências Iugu</a>
              </router-link>
              <router-link
                v-if="$checkPermission('pode-ver-saques-iugu')"
                tag="li"
                :to="{ name: 'finance-iugu-withdraws' }"
                @click.native="toggleMobileMenu"
              >
                <a>Saques Iugu</a>
              </router-link>
              <router-link
                v-if="$checkPermission('pode-ver-saques-iugu')"
                tag="li"
                :to="{ name: 'finance-iugu-withdraws-conciliation' }"
                @click.native="toggleMobileMenu"
              >
                <a>Conciliação de Saques</a>
              </router-link>
            </ul>
          </b-collapse>
        </li>
      </perfect-scrollbar>
    </nav>
  </div>
  <!--  END SIDEBAR  -->
</template>
<script>
export default {
  name: "EpalSidebar",
  data() {
    return { menu_collapse: "dashboard" };
  },

  watch: {
    $route(to) {
      const selector = document.querySelector('#sidebar a[href="' + to.path + '"]');
      if (selector) {
        const ul = selector.closest("ul.collapse");
        if (!ul) {
          const ele = document.querySelector(".dropdown-toggle.not-collapsed");
          if (ele) {
            ele.click();
          }
        }
      } else if (to.path.includes("admin")) {
        const selector = document.querySelector('a[href="#admin"]');
        if (selector.classList.contains("collapsed")) {
          selector.click();
        }
      } else if (to.path.includes("users")) {
        const selector = document.querySelector('a[href="#users"]');
        if (selector.classList.contains("collapsed")) {
          selector.click();
        }
      }
    },
  },

  mounted() {
    // default menu selection on refresh
    const selector = document.querySelector('#sidebar a[href="' + window.location.pathname + '"]');
    if (selector) {
      const ul = selector.closest("ul.collapse");
      if (ul) {
        let ele = ul.closest("li.menu").querySelectorAll(".dropdown-toggle");
        if (ele) {
          ele = ele[0];
          setTimeout(() => {
            ele.click();
          });
        }
      } else {
        selector.click();
      }
    } else if (window.location.pathname.includes("admin")) {
      const selector = document.querySelector('a[href="#admin"]');
      if (selector.classList.contains("collapsed")) {
        selector.click();
      }
    } else if (window.location.pathname.includes("users")) {
      const selector = document.querySelector('a[href="#admin"]');
      if (selector.classList.contains("collapsed")) {
        selector.click();
      }
    }
  },

  methods: {
    toggleMobileMenu() {
      if (window.innerWidth < 991) {
        this.$store.commit("toggleSideBar", !this.$store.state.is_show_sidebar);
      }
    },
  },
};
</script>
<style scoped></style>
