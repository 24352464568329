<template>
  <b-card
    header-bg-variant="transparent"
    footer-bg-variant="transparent"
    header-class="d-flex justify-content-between align-items-center"
    footer-class="d-flex justify-content-between align-items-center"
  >
    <template v-slot:header>
      <h5 class="mb-0">Contratos Ativos</h5>
      <b-button :to="{ name: 'contracts-dashboard' }" size="sm" variant="outline-primary">Contratos</b-button>
    </template>

    <b-list-group flush class="overflow-auto">
      <b-list-group-item>
        <b-row>
          <b-col cols="8">
            <span class="font-weight-bold">Contrato</span>
          </b-col>
          <b-col cols="4" class="text-right">
            <span class="font-weight-bold">Atualização</span>
          </b-col>
        </b-row>
      </b-list-group-item>
      <b-list-group-item
        v-for="(contract, index) in activeContracts"
        :key="index"
        style="cursor: pointer"
        @click="$router.push({ name: 'contract-view', params: { contractId: contract.id } })"
      >
        <b-row>
          <b-col cols="9">
            <span class="mr-auto">{{ contract.name }}</span>
          </b-col>
          <b-col cols="3" class="text-right">
            <span class="mr-auto">{{ contract.updated_at | formatDateTimeForHumans }}</span>
          </b-col>
        </b-row>
      </b-list-group-item>
    </b-list-group>
  </b-card>
</template>
<script>
import ApiService from "../../common/api.service";
export default {
  components: {},
  data() {
    return {
      isLoadingContracts: false,
      activeContracts: [],
    };
  },
  computed: {},
  beforeMount() {},
  mounted() {
    this.getActiveContracts();
  },
  methods: {
    getActiveContracts: function () {
      this.isLoadingContracts = true;
      ApiService.get("contracts/getActiveContracts")
        .then((response) => {
          if (response.data.status === "success") {
            this.activeContracts = response.data.contracts;
          }
        })
        .finally(() => {
          this.isLoadingContracts = false;
        });
    },
  },
};
</script>
