export default {
  updateChannelEpal(state, { key, value }) {
    state.channelEpal[key] = value;
  },
  updateChannelEpalPushUser(state, user) {
    state.channelEpal.activeEpalUsers.push(user);
  },
  updateChannelEpalSpliceUser(state, payload) {
    const userindex = state.channelEpal.activeEpalUsers.findIndex((usr) => usr.id === payload.userId);
    state.channelEpal.activeEpalUsers.splice(userindex, 1);
  },
};
