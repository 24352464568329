import Vue from "vue";
import store from "../store"; // Importe o Vuex Store para salvar a localização
import ApiService from "./api.service";

const LocalizationService = {
  async init() {
    const waitForUser = () => {
      return new Promise((resolve) => {
        const checkStoreReady = setInterval(() => {
          if (store.state.auth.user) {
            clearInterval(checkStoreReady);
            resolve(store.state.auth.user); // Resolve a promessa com o usuário
          }
        }, 100);
      });
    };

    // Aguardar até que o usuário esteja pronto
    const user = await waitForUser();
    let locationPermission = user.location_consent;

    if (locationPermission == 1) {
      // Se o usuário já concedeu permissão, chamamos o método de geolocalização
      this.requestLocation();
    } else if (locationPermission == 0) {
      // Se o usuário já negou, podemos exibir uma mensagem ou simplesmente não fazer nada
      console.log("Usuário negou a permissão de localização anteriormente.");
    } else {
      // Caso não haja uma resposta, perguntamos ao usuário
      Vue.swal({
        title: "Permissão de localização",
        text: "Este site deseja acessar sua localização pois é obrigatória no registro das atividades. Deseja permitir?",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Permitir",
        cancelButtonColor: "#d33",
        cancelButtonText: "Não permitir",
      }).then((willAllow) => {
        if (willAllow) {
          this.saveConsent(true); // Salvar consentimento no banco
          this.requestLocation();
        } else {
          this.saveConsent(false); // Salvar recusa no banco
          console.log("Usuário não permitiu o acesso à localização.");
        }
      });
    }
  },

  requestLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log("Posição obtida com sucesso:", position);
          store.dispatch("updateLocation", position); // Salva a localização no Vuex
        },
        (error) => {
          console.error("Erro ao obter a localização:", error);
          Vue.swal("Erro", "Não foi possível obter sua localização.", "error");
        }
      );
    } else {
      console.error("Geolocation não é suportado por este navegador.");
      swal("Erro", "Geolocalização não é suportada pelo seu navegador.", "error");
    }
  },
  // Método para salvar o consentimento no banco
  saveConsent(consentGiven) {
    const consentDate = new Date().toISOString().slice(0, 19).replace("T", " "); // Formata para 'YYYY-MM-DD HH:MM:SS'

    ApiService.post("users/save-location-consent", {
      location_consent: consentGiven,
      location_consent_date: consentDate,
      location_consent_method: "site web", // Método pelo qual o consentimento foi obtido
    })
      .then((response) => {
        console.log("Consentimento salvo com sucesso", response);
      })
      .catch((error) => {
        console.error("Erro ao salvar consentimento", error);
      });
  },
};

export default LocalizationService;
