import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ApiService from "./common/api.service";
import _ from "lodash";
import moment from "moment";
import VueCookies from "vue-cookies";
import "./filters";

import { BootstrapVue } from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue.css";

import PerfectScrollbar from "vue2-perfect-scrollbar";
import VueScrollactive from "vue-scrollactive";
import VueMeta from "vue-meta";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import PortalVue from "portal-vue";
import i18n from "./i18n";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

import VueTheMask from "vue-the-mask";

import CKEditor from "@ckeditor/ckeditor5-vue2";
Vue.use(CKEditor);

import appSetting from "./app-setting";
import LocalizationService from "./common/localization";

Vue.use(BootstrapVue);

Vue.use(VueCookies, { expire: "30d" });
window.axios = require("axios");

moment.locale("pt-Br");

Vue.use(PerfectScrollbar);
Vue.use(VueScrollactive);
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});

const options = {
  confirmButtonColor: "#4361ee",
  cancelButtonColor: "#e7515a",
};
Vue.use(VueSweetalert2, options);
Vue.use(PortalVue);
Vue.component("VSelect", vSelect);
Vue.use(VueTheMask);

Vue.config.productionTip = false;
Vue.prototype.$appSetting = appSetting;

Vue.use(_);
ApiService.init();
appSetting.init();

LocalizationService.init();

Vue.prototype.$checkEpalRole = function (value) {
  if (store.state.auth.user.roles) {
    if (store.state.auth.user.roles.findIndex((role) => role.name === value) > -1) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

Vue.prototype.$checkPermission = function (value) {
  const isSuperAdmin = (role) => role.name == "super-admin";
  if (store.state.auth.user.roles.findIndex(isSuperAdmin) > -1) {
    return true;
  }
  if (store.state.auth.userpermissions) {
    if (store.state.auth.userpermissions.includes(value)) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

Vue.prototype.$checkProjectPermission = function (value) {
  const isSuperAdmin = (role) => role.name == "super-admin";
  if (store.state.auth.user.roles.findIndex(isSuperAdmin) > -1) {
    return true;
  }
  if (store.state.projectData.projectUserPermissions) {
    if (store.state.projectData.projectUserPermissions.includes(value)) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

Vue.prototype.$showToast = function (varTitle, varVariant, varMsg) {
  // Use a shorter name for this.$createElement
  const h = this.$createElement;
  // Create the message
  const vNodesMsg = h("p", { class: ["text-dark", "mb-0"] }, [varMsg]);
  // Create the title
  const vNodesTitle = h("div", { class: ["d-flex", "flex-grow-1", "align-items-baseline", "mr-2"] }, [
    h("strong", { class: "mr-2" }, varTitle),
    h("small", { class: "ml-auto text-italics" }, moment().format("h:mm:ss")),
  ]);
  // Pass the VNodes as an array for message and title
  this.$bvToast.toast([vNodesMsg], {
    title: [vNodesTitle],
    solid: true,
    variant: varVariant,
    autoHideDelay: 10000,
  });
};

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
