const routes = [
  {
    path: "/items",
    name: "items",
    component: () => import(/* webpackChunkName: "items" */ "../views/items/items.vue"),
    meta: { section_name: "items" },
    props: true,
  },
  {
    path: "/items/new",
    name: "item-create-new",
    component: () => import(/* webpackChunkName: "item-create-new" */ "../views/items/create-item-new.vue"),
    meta: { section_name: "items" },
    props: true,
  },
];
export default routes;
