import mutations from "./mutations";

const state = () => ({
  latitude: null,
  longitude: null,
  accuracy: null,
});

const actions = {
  updateLocation({ commit }, position) {
    const { latitude, longitude, accuracy } = position.coords;
    commit("setLocation", { latitude, longitude, accuracy });
  },
};

const getters = {};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
  getters,
};
