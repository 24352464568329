<template>
  <b-card
    header-bg-variant="transparent"
    footer-bg-variant="transparent"
    header-class="d-flex justify-content-between align-items-center"
    footer-class="d-flex justify-content-between align-items-center"
  >
    <template v-slot:header>
      <h5 class="mb-0">Projetos Ativos</h5>
      <b-button :to="{ name: 'projects-dashboard' }" size="sm" variant="outline-primary">Projetos</b-button>
    </template>

    <b-list-group flush class="overflow-auto">
      <b-list-group-item>
        <b-row>
          <b-col cols="8">
            <span class="font-weight-bold">Projeto</span>
          </b-col>
          <b-col cols="4" class="text-right">
            <span class="font-weight-bold">Atualização</span>
          </b-col>
        </b-row>
      </b-list-group-item>
      <b-list-group-item
        v-for="(project, index) in activeProjects"
        :key="index"
        style="cursor: pointer"
        :to="{ name: 'project-view', params: { projectId: project.id, activeTab: 'informations' } }"
      >
        <b-row>
          <b-col cols="9">
            <span class="mr-auto">{{ project.name }}</span>
          </b-col>
          <b-col cols="3" class="text-right">
            <span class="mr-auto">{{ project.updated_at | formatDateTimeForHumans }}</span>
          </b-col>
        </b-row>
      </b-list-group-item>
    </b-list-group>
  </b-card>
</template>
<script>
import ApiService from "../../common/api.service";
export default {
  name: "WidgetProjects",
  components: {},
  data() {
    return {
      isLoadingProjects: false,
      activeProjects: [],
    };
  },
  computed: {},
  beforeMount() {},
  mounted() {
    this.getActiveProjects();
  },
  methods: {
    getActiveProjects: function () {
      this.isLoadingProjects = true;
      ApiService.get("projects/getActiveProjects")
        .then((response) => {
          if (response.data.status === "success") {
            this.activeProjects = response.data.projects;
          }
        })
        .finally(() => {
          this.isLoadingProjects = false;
        });
    },
  },
};
</script>
