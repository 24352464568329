export default {
  updateIsLoadingProject(state, value) {
    state.isLoadingProject = value;
  },
  updateProject(state, value) {
    state.project = value;
  },
  updateProjectMenuName(state, value) {
    state.projectMenuName = value;
  },
  updateProjectSubMenuName(state, value) {
    state.projectSubMenuName = value;
  },
  updateProjectTeam(state, value) {
    state.projectteam = value;
  },
  updateProjectUserRole(state, value) {
    state.projectUserRole = value;
  },
  updateProjectUserPermissions(state, value) {
    state.projectUserPermissions = value;
  },
};
