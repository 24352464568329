/* eslint-disable no-undef */
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "./jwt.service";
import store from "../store";
import router from "../router";
import Echo from "laravel-echo";

const ApiService = {
  init() {
    Vue.use(VueAxios, axios);

    Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL;

    Vue.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
    // Vue.axios.defaults.headers.common['socketId'] =  Echo.socketId();

    Vue.axios.interceptors.response.use(
      (response) => {
        if (response.data.status === "unauthorized") {
          router.push({ name: "Home" });
          Vue.swal({
            title: "Acesso Restrito",
            html: response.data.message,
            icon: "error",
          });
        }
        if (response.data.status === "firstLogin") {
          router.push({ name: "edit-user", params: { userId: Number(response.data.user.id) } });
        }
        if (response.status === 200 || response.status === 201) {
          return Promise.resolve(response);
        } else {
          return Promise.reject(response);
        }
      },

      (error) => {
        if (error.response) {
          if (error.response.status) {
            switch (error.response.status) {
              case 302:
                JwtService.destroyToken();
                JwtService.destroyUser();
                JwtService.destroyUserPermissions();
                delete axios.defaults.headers.common.Authorization;
                window.location.href = "/login";
                break;
              case 400:
                // do something
                break;

              case 401:
                JwtService.destroyToken();
                JwtService.destroyUser();
                JwtService.destroyUserPermissions();
                delete axios.defaults.headers.common.Authorization;
                window.location.href = "/login";
                break;
              case 403:
                JwtService.destroyToken();
                JwtService.destroyUser();
                JwtService.destroyUserPermissions();
                delete axios.defaults.headers.common.Authorization;

                window.location.href = "/login";
                break;
              case 404:
                // alert("page not exist");
                break;
              case 419:
                JwtService.destroyToken();
                JwtService.destroyUser();
                JwtService.destroyUserPermissions();
                delete axios.defaults.headers.common.Authorization;

                window.location.href = "/login";
                break;
              case 502:
                setTimeout(() => {
                  if (store.state.auth.user) {
                    Echo.leave(`private-App.Models.User.${store.state.auth.user.id}`);
                  }

                  JwtService.destroyToken();
                  JwtService.destroyUser();
                  JwtService.destroyUserPermissions();
                  delete axios.defaults.headers.common.Authorization;

                  window.location.href = "/login";
                }, 1000);
                break;
              default:
                return Promise.resolve(error.response);
            }

            return Promise.reject(error.response);
          }
        }
      }
    );
  },

  setHeader() {
    Vue.axios.defaults.headers.common.Authorization = `Bearer ${JwtService.getToken()}`;
  },
  destroyHeader() {
    delete Vue.axios.defaults.headers.common.Authorization;
  },
  query(resource, params) {
    return Vue.axios.get(resource, params).catch((error) => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },

  get(resource, slug = "") {
    if (JwtService.getToken() !== null) {
      this.setHeader();
    }
    let params = "";
    if (!_.isNaN(slug)) {
      params = "?" + slug;
    }
    if (_.isObject(slug)) {
      params =
        "?" +
        _.keys(slug)
          .filter((key) => slug[key] != null && slug[key] !== "null")
          .map((key) => key + "=" + slug[key])
          .join("&");
    }
    return Vue.axios.get(`${resource}${params}`).catch((error) => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },

  post(resource, params) {
    if (JwtService.getToken() !== null) {
      this.setHeader();
    }
    return Vue.axios.post(`${resource}`, params);
  },

  postfile(resource, params) {
    if (JwtService.getToken() !== null) {
      this.setHeader();
    }
    return Vue.axios.post(`${resource}`, params, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },

  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  async delete(resource) {
    try {
      return await Vue.axios.delete(resource);
    } catch (error) {
      throw new Error(`[RWV] ApiService ${error}`);
    }
  },
};

export default ApiService;
