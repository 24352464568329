<template>
  <div>
    <b-card
      header-bg-variant="transparent"
      footer-bg-variant="transparent"
      header-class="d-flex justify-content-between align-items-center"
      footer-class="d-flex justify-content-between align-items-center"
      class="h-100"
    >
      <template v-slot:header>
        <h5 class="mb-0">Atividades</h5>
        <b-button :to="{ name: 'activities-dashboard' }" size="sm" variant="outline-primary">Atividades</b-button>
      </template>
      <b-row>
        <b-col>
          <b-button
            size="sm"
            block
            variant="outline-primary"
            @click="$refs.createProjectActivityModal.openCreateActivityModal()"
          >
            <i class="fa-regular fa-plus mr-1"></i> LANÇAR NOVA ATIVIDADE
          </b-button>
        </b-col>
      </b-row>

      <b-row class="h-100" v-if="activities.length == 0">
        <b-col class="d-flex justify-content-center align-items-center">
          <div>Nenhuma atividade em andamento</div>
        </b-col>
      </b-row>

      <b-list-group flush class="overflow-auto" v-else>
        <b-list-group-item>
          <b-row>
            <b-col cols="5">
              <span class="font-weight-bold">Projeto / Atividade</span>
            </b-col>
            <b-col cols="4">
              <span class="font-weight-bold">Resp.</span>
            </b-col>
            <b-col cols="3" class="text-right">
              <span class="font-weight-bold">Prazo</span>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item
          :to="{
            name: 'project-activity',
            params: {
              activityId: activity.id,
              projectId: activity.project_id,
            },
          }"
          v-for="(activity, index) in activities"
          :key="index"
        >
          <b-row :class="getActClass(activity.start_date)">
            <b-col cols="5">
              <span class="project-info">{{ activity.project.name }}</span>
              <br />
              <span class="project-info" v-if="activity.activity_code">{{ activity.activity_code + " - " }}</span>
              <span class="project-info">{{ activity.title }}</span>
            </b-col>

            <b-col cols="4" class="text-truncate">
              <span class="project-info">{{ activity.responsable?.name || activity.created_by?.name }}</span>
            </b-col>
            <b-col cols="3" class="text-right">
              <span class="project-info">{{ activity.estimated_end_date | formatDateTimeForHumans }}</span>
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>
    </b-card>
    <create-project-activity-modal
      ref="createProjectActivityModal"
      @created="onActivityCreated"
    ></create-project-activity-modal>
    <create-activity-progress-modal
      ref="createActivityProgressModal"
      @created="onActivityProgressCreated"
    ></create-activity-progress-modal>
  </div>
</template>
<script>
import ApiService from "../../common/api.service";
import createProjectActivityModal from "../activities/create-project-activity-modal.vue";
import createActivityProgressModal from "../activities/create-project-activity-progress-modal.vue";

import moment from "moment";
moment.locale("pt-Br");
export default {
  name: "WidgetActivities",
  components: { createProjectActivityModal, createActivityProgressModal },
  data() {
    return {
      isLoadingActivities: false,
      activities: [],
    };
  },
  computed: {},
  beforeMount() {},
  mounted() {
    this.getWidgetActivities();
  },
  methods: {
    getWidgetActivities: function () {
      this.isLoadingActivities = true;
      ApiService.get("activities/getWidgetActivities")
        .then((response) => {
          if (response.data.status === "success") {
            this.activities = response.data.activities;
          }
        })
        .finally(() => {
          this.isLoadingActivities = false;
        });
    },
    getActClass: function (start_date) {
      let result = null;
      if (moment().isAfter(start_date, "day")) {
        result = "text-danger";
      }
      return result;
    },
    onActivityCreated: function () {
      //
    },
    onActivityProgressCreated: function () {
      //
    },
  },
};
</script>
<style scoped>
.project-info {
  font-size: 11px !important;
}
.project-info-detail {
  font-size: 10px !important;
  font-style: italic;
}
</style>
