<template>
  <div class="form auth-boxed">
    <div class="form-container outer">
      <div class="form-form">
        <div class="form-form-wrap">
          <div class="form-container">
            <div class="form-content">
              <h1 class="">Registre uma senha</h1>
              <b-form class="text-left">
                <div class="form">
                  <div id="username-field" class="field-wrapper input">
                    <label for="username">USERNAME</label>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-user"
                    >
                      <path
                        d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"
                      ></path>
                      <circle cx="12" cy="7" r="4"></circle>
                    </svg>
                    <b-input
                      v-model="inviteUser.name"
                      placeholder="Username"
                      disabled
                    ></b-input>
                  </div>

                  <div id="email-field" class="field-wrapper input">
                    <label for="email">EMAIL</label>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-at-sign register"
                    >
                      <circle cx="12" cy="12" r="4"></circle>
                      <path
                        d="M16 8v5a3 3 0 0 0 6 0v-1a10 10 0 1 0-3.92 7.94"
                      ></path>
                    </svg>
                    <b-input
                      type="email"
                      v-model="inviteUser.email"
                      placeholder="Email"
                      disabled
                    ></b-input>
                  </div>

                  <div id="password-field" class="field-wrapper input mb-2">
                    <div class="d-flex justify-content-between">
                      <label for="password">PASSWORD</label>
                      <router-link
                        to="/auth/pass-recovery-boxed"
                        class="forgot-pass-link"
                        >Forgot Password?</router-link
                      >
                    </div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-lock"
                    >
                      <rect
                        x="3"
                        y="11"
                        width="18"
                        height="11"
                        rx="2"
                        ry="2"
                      ></rect>
                      <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                    </svg>
                    <b-input
                      :type="pwd_type"
                      v-model="password"
                      placeholder="Password"
                    ></b-input>
                    <svg
                      @click="set_pwd_type"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      id="toggle-password"
                      class="feather feather-eye"
                    >
                      <path
                        d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"
                      ></path>
                      <circle cx="12" cy="12" r="3"></circle>
                    </svg>
                  </div>
                  <!-- 
                  <div class="field-wrapper terms_condition">
                    <b-checkbox class="checkbox-outline-primary"
                      >I agree to the
                      <a href="javascript:void(0);">
                        terms and conditions
                      </a></b-checkbox
                    >
                  </div> -->

                  <div class="d-sm-flex justify-content-between">
                    <div class="field-wrapper">
                      <b-button
                        :disabled="!password"
                        @click="savePasswordByInvite()"
                        variant="success"
                        >Salvar senha</b-button
                      >
                    </div>
                  </div>
                </div>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/sass/authentication/auth-boxed.scss";
import ApiService from "@/common/api.service";
export default {
  inheritAttrs: false, // Por padrão, herda atributos do componente pai
  name: "ConfirmInvite",
  props: { code: null },
  metaInfo: { title: "Register Boxed" },
  data() {
    return {
      pwd_type: "password",
      inviteUser: { name: null, email: null },
      password: null,
    };
  },
  mounted() {
    this.getDataInvite();
  },
  methods: {
    set_pwd_type() {
      if (this.pwd_type == "password") {
        this.pwd_type = "text";
      } else {
        this.pwd_type = "password";
      }
    },

    getDataInvite() {
      ApiService.post("getInvitationByNumber", {
        invitationToken: this.code,
      })
        .then((response) => {
          console.log(response);
          this.inviteUser = response.data.invitation;
        })
        .finally(() => {
          this.isLoadingusers = false;
        })
        .catch((error) => {
          console.log(error);
          this.$showToast(
            "Erro",
            "danger",
            "Ocorreu um erro durante a consulta."
          );
        });
    },
    savePasswordByInvite: function () {
      ApiService.post("savePasswordByInvite", {
        email: this.inviteUser.email,
        name: this.inviteUser.name,
        password: this.password,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "success") {
            this.$showToast(
              "Sucesso",
              "success",
              "Cadastro realizado com sucesso."
            );
            window.location.href = "/login";
          }
        })
        .finally(() => {
          this.isLoadingusers = false;
        })
        .catch((error) => {
          console.log(error);
          this.$showToast(
            "Erro",
            "danger",
            "Ocorreu um erro durante a consulta."
          );
        });
    },
  },
};
</script>
