<template>
  <b-card
    header-bg-variant="transparent"
    footer-bg-variant="transparent"
    header-class="d-flex justify-content-start align-items-center"
    footer-class="d-flex justify-content-between align-items-center"
  >
    <template v-slot:header>
      <h5 class="mb-0">Progresso</h5>
      <!-- <b-button :to="{ name: 'projects-dashboard' }" size="sm" variant="outline-primary">Projetos</b-button> -->
    </template>

    <b-row>
      <b-col>
        <!-- <b-list-group flush>
          <b-list-group-item v-for="(row, index) in todayUserPerformance" :key="index">
            {{ row.date }} - {{ row.total_hours }}
          </b-list-group-item>
        </b-list-group> -->
        <GChart
          type="ComboChart"
          id="comboChart"
          :data="chartData"
          :options="chartOptions"
          @ready="onUserBySchoolChartReady"
        />
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import ApiService from "../../common/api.service";
import moment from "moment"; // Importa o Moment.js
import { GChart } from "vue-google-charts/legacy";

export default {
  name: "WidgetUserProgress",
  components: { GChart },
  data() {
    return {
      isLoadingGoal: false,
      todayUserPerformance: [],

      chartData: [["Dia", "Horas Relatadas", "Meta (8 horas)"]],

      chartOptions: {
        title: "Progresso de Atividades nos Últimos 5 Dias",
        hAxis: {
          format: "d/M/yy",
          slantedText: false, // Não inclinar o texto para manter todos visíveis
          showTextEvery: 1, // Mostrar todos os rótulos no eixo X
          ticks: [],
        },

        vAxis: {
          title: "Horas",
          minValue: 0, // Definir o valor mínimo como 0
          ticks: [0, 2, 4, 6, 8, 10, 12], // Quebrar a escala de 2 em 2
        },
        legend: {
          position: "bottom",
        }, // Legenda na parte inferior
        seriesType: "bars", // Define o tipo de gráfico padrão como barras
        series: {
          1: { type: "line", curveType: "function" }, // Define a série 1 (Meta) como linha contínua
        },
      },
    };
  },
  computed: {},
  beforeMount() {},
  mounted() {},
  methods: {
    onUserBySchoolChartReady: function () {
      this.loadGoogleCharts(); // Carrega o Google Charts ao montar o componente
      this.fetchProgressData(); // Busca os dados de progresso
    },
    loadGoogleCharts() {
      // Carrega a biblioteca do Google Charts
      google.charts.load("current", { packages: ["corechart"] });
      google.charts.setOnLoadCallback(this.drawChart);
    },

    drawChart() {
      try {
        if (typeof google != "undefined") {
          // Cria os dados do gráfico
          // Define as opções do gráfico
          // Renderiza o gráfico
        }
      } catch (error) {
        console.log(error);
      }
    },
    fetchProgressData() {
      this.isLoadingGoal = true;
      ApiService.get(`/users/performance/getTodayUserPerformance/${this.$store.state.auth.user.id}`)
        .then((response) => {
          if (response.data.status === "success") {
            this.todayUserPerformance = response.data.todayUserPerformance;
            const progress = this.todayUserPerformance;

            // Reinicializa os dados do gráfico
            // this.chartData = [["Dia", "Horas Relatadas", "Meta (8 horas)"]];

            this.chartData = new google.visualization.DataTable();
            this.chartData.addColumn("date", "Dia");
            this.chartData.addColumn("number", "Horas Relatadas");
            this.chartData.addColumn("number", "Meta");

            // Gerar os últimos 5 dias como strings formatadas
            let lastFiveDays = [];
            for (let i = 0; i < 5; i++) {
              let date = moment().subtract(i, "days").format("DD/MM/YYYY"); // Usa Moment.js para formatar a data como string
              lastFiveDays.push({ date: date, total_hours: 0 });
            }

            // Substituir dias com dados reais
            lastFiveDays.forEach((day) => {
              let progressEntry = progress.find((item) => moment(item.date).format("DD/MM/YYYY") === day.date);
              if (progressEntry) {
                day.total_hours = Number(progressEntry.total_hours);
              }
            });
            // console.log("Dados originais para o gráfico:", lastFiveDays);
            // Adiciona os dados ao gráfico, mesmo os dias sem movimento
            lastFiveDays.reverse().forEach((day) => {
              var dateString = day.date; // Oct 23
              var dateParts = dateString.split("/");
              // month is 0-based, that's why we need dataParts[1] - 1
              var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
              this.chartOptions.hAxis.ticks.push(dateObject);
              this.chartData.addRow([dateObject, day.total_hours, 8]);
            });

            // Log para verificação de dados
            console.log("Dados para o gráfico:", this.chartData);
          }
        })
        .finally(() => {
          this.isLoadingGoal = false;
        });
    },
  },
};
</script>
<style scoped>
/*  */
</style>
