/* eslint-disable no-unused-vars */
import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes.js";
import contracts from "./contracts.js";
import items from "./items.js";
import activities from "./activities.js";
import projects from "./projects.js";
import reports from "./reports.js";
import engineers from "./engineers.js";
import education from "./education.js";
import store from "../store";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  linkExactActiveClass: "active",
  routes: [...routes, ...contracts, ...items, ...activities, ...projects, ...reports, ...engineers, ...education],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  store.commit("setRouteMetaLayout", to.meta.layout);
  if (to.meta && to.meta.layout && to.meta.layout === "auth") {
    store.commit("setLayout", "auth");
  } else {
    store.commit("setLayout", "app");
  }

  if (to.name !== "login") {
    // Vou gravar a página de navegação para retornar para ela depois
    if (Vue.$cookies.isKey("user_active_company")) {
      Vue.$cookies.set("epal_navigation_page", {
        companyId: Vue.$cookies.get("user_active_company").id,
        page: to.path,
      });
    }
  }

  next(true);
});
router.afterEach((to, from) => {});

export default router;
