<template>
  <ul id="topAccordion" class="list-unstyled menu-categories">
    <li class="menu single-menu">
      <router-link to="/" class="dropdown-toggle autodroprown">
        <div class="">
          <i class="fa-light fa-house fa-xl" style="overflow: unset; margin-right: 14px" />
          <span>{{ $t("dashboard") }}</span>
        </div>
      </router-link>
    </li>

    <li class="menu single-menu">
      <a href="javascript:void(0)" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">
        <div class="">
          <i class="fa-light fa-database fa-xl" style="overflow: unset; margin-right: 14px" />
          <span>{{ $t("registrations") }}</span>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-chevron-down"
        >
          <polyline points="6 9 12 15 18 9" />
        </svg>
      </a>
      <ul id="datatables" class="collapse submenu list-unstyled" data-parent="#topAccordion">
        <!-- <router-link tag="li" to="/users/profile"><a>Profile</a></router-link> -->

        <router-link
          tag="li"
          :to="{
            name: 'users',
          }"
          v-if="$checkPermission('ver-usuarios')"
        >
          <a>Usuários</a>
        </router-link>
        <router-link
          tag="li"
          :to="{
            name: 'companies',
          }"
          v-if="$checkPermission('ver-empresas')"
        >
          <a>Empresas</a>
        </router-link>

        <router-link
          tag="li"
          :to="{
            name: 'items',
          }"
        >
          <a>Itens de Contratos</a>
        </router-link>
      </ul>
    </li>
    <li class="menu single-menu">
      <a href="javascript:void(0)" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">
        <div class="">
          <i class="fa-light fa-diagram-project fa-xl" style="overflow: unset; margin-right: 14px" />
          <span>Projetos</span>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-chevron-down"
        >
          <polyline points="6 9 12 15 18 9" />
        </svg>
      </a>
      <ul id="datatables" class="collapse submenu list-unstyled" data-parent="#topAccordion">
        <router-link
          tag="li"
          :to="{
            name: 'projects-dashboard',
          }"
        >
          <a>Projetos</a>
        </router-link>
        <router-link
          tag="li"
          :to="{
            name: 'contracts-dashboard',
          }"
        >
          <a>Contratos</a>
        </router-link>
        <router-link
          tag="li"
          :to="{
            name: 'activities-dashboard',
          }"
        >
          <a>Atividades</a>
        </router-link>
      </ul>
    </li>
    <li class="menu single-menu" v-if="$checkPermission('ver-relatorios')">
      <a href="javascript:void(0)" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">
        <div class="">
          <i class="fa-light fa-diagram-project fa-xl" style="overflow: unset; margin-right: 14px" />
          <span>Relatórios</span>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-chevron-down"
        >
          <polyline points="6 9 12 15 18 9" />
        </svg>
      </a>
      <ul id="datatables" class="collapse submenu list-unstyled" data-parent="#topAccordion">
        <router-link
          v-if="$checkPermission('ver-list-activity-progress')"
          tag="li"
          :to="{
            name: 'list-activity-progress',
          }"
        >
          <a>Progresso</a>
        </router-link>
        <router-link
          v-if="$checkPermission('ver-performance-report')"
          tag="li"
          :to="{
            name: 'performance-report',
          }"
        >
          <a>Desempenho</a>
        </router-link>
      </ul>
    </li>
  </ul>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "HeaderMenu",
  filters: {},
  props: {
    workId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      userpermissions: (state) => state.auth.userpermissions,
    }),
  },
  watch: {},
  methods: {},
};
</script>

<!-- styles -->
<!-- adding scoped attribute will apply the css to this component only -->
<style scoped></style>
