<template>
  <div>
    <!--  BEGIN NAVBAR  -->
    <Header />
    <!--  END NAVBAR  -->

    <!--  BEGIN MAIN CONTAINER  -->
    <div
      id="container"
      class="main-container"
      :class="[
        !$store.state.is_show_sidebar ? 'sidebar-closed sbar-open' : '',
        $store.state.menu_style === 'collapsible-vertical' ? 'collapsible-vertical-mobile' : '',
      ]"
    >
      <!--  BEGIN OVERLAY  -->
      <div
        class="overlay"
        :class="{ show: !$store.state.is_show_sidebar }"
        @click="$store.commit('toggleSideBar', !$store.state.is_show_sidebar)"
      />
      <div
        class="search-overlay"
        :class="{ show: $store.state.is_show_search }"
        @click="$store.commit('toggleSearch', !$store.state.is_show_search)"
      />
      <!-- END OVERLAY -->

      <!--  BEGIN SIDEBAR  -->
      <Sidebar />
      <!--  END SIDEBAR  -->

      <!--  BEGIN CONTENT AREA  -->

      <div id="content" class="main-content">
        <router-view>
          <!-- BEGIN LOADER -->
          <div id="load_screen">
            <div class="loader">
              <div class="loader-content">
                <div class="spinner-grow align-self-center" />
              </div>
            </div>
          </div>
          <!--  END LOADER -->
        </router-view>

        <!-- BEGIN FOOTER -->
        <!-- <Footer></Footer> -->
        <!-- END FOOTER -->
      </div>
      <!--  END CONTENT AREA  -->

      <!-- BEGIN APP SETTING LAUNCHER -->
      <!-- <app-settings /> -->
      <!-- END APP SETTING LAUNCHER -->
    </div>
  </div>
</template>
<script>
import ApiService from "../common/api.service";
import Header from "@/components/layout/header.vue";
import Sidebar from "@/components/layout/sidebar.vue";

export default {
  components: {
    // eslint-disable-next-line vue/no-reserved-component-names
    Header,
    Sidebar,
  },
  data() {
    return {
      //
    };
  },
  computed: {
    projectId: function () {
      return this.$route.params.projectId;
    },
  },
  watch: {
    projectId: function () {
      if (this.$route.params.projectId && this.$store.state.routeMetaLayout === "projects") {
        this.getInitialProjectData(this.$route.params.projectId);
      } else {
        this.$store.commit("updateProject", null);
        this.$store.commit("updateProjectTeam", null);
        this.$store.commit("updateProjectUserRole", null);
        this.$store.commit("updateProjectUserPermissions", []);
      }
    },
  },
  methods: {
    getInitialProjectData: function (projectId) {
      this.$store.commit("updateIsLoadingProject", true);
      ApiService.get(`projects/getProject`, {
        projectId,
      })
        .then((response) => {
          if (response.data.status === "success") {
            this.$store.commit("updateProject", response.data.project);
            this.$store.commit("updateProjectTeam", response.data.projectteam.members);
            this.$store.commit("updateProjectUserRole", response.data.projectUserRole);
            this.$store.commit("updateProjectUserPermissions", response.data.projectUserPermissions);
          } else {
            if (response.data.error_code === "user_not_in_project") {
              this.$showToast("Erro", "danger", response.data.message);
              this.$router.push({ name: "Home" });
            } else {
              this.$showToast("Erro", "danger", "Ocorreu um erro durante a consulta.");
              this.$router.push({ name: "Home" });
            }
          }
        })
        .finally(() => {
          this.$store.commit("updateIsLoadingProject", false);
        });
    },
  },
};
</script>
<style scoped>
.sub-menu {
  position: -webkit-sticky;
  position: sticky;
  top: 54px;
  z-index: 12 !important;
  box-shadow: 0px 20px 20px rgb(126 142 177 / 12%);
}
</style>
