const routes = [
  {
    path: "/education",
    name: "education",
    component: () => import(/* webpackChunkName: "education" */ "../views/education/index.vue"),
    meta: { section_name: "education" },
  },
  {
    path: "/education/articles",
    name: "education-articles",
    component: () => import(/* webpackChunkName: "education-articles" */ "../views/education/articles.vue"),
    meta: { section_name: "education" },
  },
  {
    path: "/education/article/:articleId",
    name: "education-article-view",
    component: () => import(/* webpackChunkName: "education-article-view" */ "../views/education/article-view.vue"),
    meta: { section_name: "education" },
    props: (route) => {
      return {
        ...route.params,
        ...{
          articleId: Number.parseInt(route.params.articleId, 10),
        },
      };
    },
  },
];
export default routes;
