var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-card',{staticClass:"h-100",attrs:{"header-bg-variant":"transparent","footer-bg-variant":"transparent","header-class":"d-flex justify-content-between align-items-center","footer-class":"d-flex justify-content-between align-items-center"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h5',{staticClass:"mb-0"},[_vm._v("Atividades")]),_c('b-button',{attrs:{"to":{ name: 'activities-dashboard' },"size":"sm","variant":"outline-primary"}},[_vm._v("Atividades")])]},proxy:true}])},[_c('b-row',[_c('b-col',[_c('b-button',{attrs:{"size":"sm","block":"","variant":"outline-primary"},on:{"click":function($event){return _vm.$refs.createProjectActivityModal.openCreateActivityModal()}}},[_c('i',{staticClass:"fa-regular fa-plus mr-1"}),_vm._v(" LANÇAR NOVA ATIVIDADE ")])],1)],1),(_vm.activities.length == 0)?_c('b-row',{staticClass:"h-100"},[_c('b-col',{staticClass:"d-flex justify-content-center align-items-center"},[_c('div',[_vm._v("Nenhuma atividade em andamento")])])],1):_c('b-list-group',{staticClass:"overflow-auto",attrs:{"flush":""}},[_c('b-list-group-item',[_c('b-row',[_c('b-col',{attrs:{"cols":"5"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Projeto / Atividade")])]),_c('b-col',{attrs:{"cols":"4"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Resp.")])]),_c('b-col',{staticClass:"text-right",attrs:{"cols":"3"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Prazo")])])],1)],1),_vm._l((_vm.activities),function(activity,index){return _c('b-list-group-item',{key:index,attrs:{"to":{
          name: 'project-activity',
          params: {
            activityId: activity.id,
            projectId: activity.project_id,
          },
        }}},[_c('b-row',{class:_vm.getActClass(activity.start_date)},[_c('b-col',{attrs:{"cols":"5"}},[_c('span',{staticClass:"project-info"},[_vm._v(_vm._s(activity.project.name))]),_c('br'),(activity.activity_code)?_c('span',{staticClass:"project-info"},[_vm._v(_vm._s(activity.activity_code + " - "))]):_vm._e(),_c('span',{staticClass:"project-info"},[_vm._v(_vm._s(activity.title))])]),_c('b-col',{staticClass:"text-truncate",attrs:{"cols":"4"}},[_c('span',{staticClass:"project-info"},[_vm._v(_vm._s(activity.responsable?.name || activity.created_by?.name))])]),_c('b-col',{staticClass:"text-right",attrs:{"cols":"3"}},[_c('span',{staticClass:"project-info"},[_vm._v(_vm._s(_vm._f("formatDateTimeForHumans")(activity.estimated_end_date)))])])],1)],1)})],2)],1),_c('create-project-activity-modal',{ref:"createProjectActivityModal",on:{"created":_vm.onActivityCreated}}),_c('create-activity-progress-modal',{ref:"createActivityProgressModal",on:{"created":_vm.onActivityProgressCreated}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }